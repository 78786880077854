/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-lg -8>
    <v-flex xs12>
      <v-tabs active-class="tab-active" class="mytab">
        <v-tab :ripple="false" :to="{ name: 'settings' }">
          {{ $t("pages.settings.profile") }}
        </v-tab>
        <!--        <v-tab :ripple="false" :to="{ name: 'settings.shift' }">-->
        <!--          {{ $t("pages.settings.shift") }}-->
        <!--        </v-tab>-->
        <!--        <v-tab :ripple="false" :to="{ name: 'settings.service' }">-->
        <!--          {{ $t("pages.settings.service") }}-->
        <!--        </v-tab>-->
        <!--        <v-tab :ripple="false" :to="{ name: 'settings.notification' }">-->
        <!--          {{ $t("pages.settings.notification") }}-->
        <!--        </v-tab>-->
      </v-tabs>
    </v-flex>
    <v-flex xs12>
      <router-view></router-view>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Settings",
  computed: {
    userType() {
      return localStorage.getItem("type");
    },
  },
};
</script>

<style scoped>
.v-tab {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #a6aebe;
  text-transform: none !important;
}

.tab-active {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #081734;
  text-transform: none;
}

.mytab {
  border-bottom: 1px solid #d1d8de;
}
</style>
<style>
.v-slide-group__prev {
  display: none !important;
  visibility: hidden;
}
</style>