var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg": "",
        "-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-tabs",
            { staticClass: "mytab", attrs: { "active-class": "tab-active" } },
            [
              _c(
                "v-tab",
                { attrs: { ripple: false, to: { name: "settings" } } },
                [_vm._v(" " + _vm._s(_vm.$t("pages.settings.profile")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-flex", { attrs: { xs12: "" } }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }